import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 style={{ padding: "0.5em" }}>Pass</h1>
        <h3>Instantly approve your passes</h3>
        <button onClick={async () => {window.open("https://qrazy-serverless.vercel.app/api/fetch?location=Pass&redir=https://www.youtube.com/watch?v=oHg5SJYRHA0&url=2wZ6fA23VLnsrgsR9iXrDMDPbAJAvR9PRywELqJsMaQCEGDBE7jydJSQRW-kyvxQm3oxpU49KFsOmKJkX8mog2HeTVj8NUR6QsJz9GFJsnDK03XQQg3soVTkakO4bnfX35lo97RJLjpI6x86yDX1lJnM3EdwyRyS4RQ4ohFGknLVIa_bpPhlgcVAF_kUsnj59XIfbPM=")}} style={{ margin: "1em", padding: "1em", width: "8em", fontWeight: "bold", fontSize: 30, color: "#fff", backgroundColor: "rgba(255,255,255,0.2)", border: "none", borderRadius: "2rem" }}>Launch App</button>
      </header>
    </div>
  );
}

export default App;
